import React, { useState, useRef, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import Survey from './components/Survey';
import StatusTag from './renders/StatusTag';
import CheckStatus from './renders/CheckStatus';
import EditSurvey from './renders/EditSurvey';
import EditDeployment from './renders/EditDeployment';
import ProfDevActions from './renders/ProfDevActions';
import GoalAttendance from './renders/GoalAttendance';
import Trainings from './renders/Trainings';
import CheckTrue from './renders/CheckTrue';
import SelectedOrgs from './renders/SelectedOrgs';
import NameOnly from './renders/NameOnly';
import MeetingType from './renders/MeetingType';
import LastUpdatedBy from './renders/LastUpdatedBy';
import style from './style.css';
import dashboardConfig from '../config.js';
import progressQuery from '../graphql/helpers/progress.graphql';
import { setOrgListChanged, setOrgList } from '../../../reducers/orgList.js';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';

const Fortress = window.cccisd.fortress;
const DashboardTable = props => {
    const { groupingUnitId, deploymentInfo, actingRole, selectOptions, questProjectId, orgMember } = props;
    const dispatch = useDispatch();
    const [disableTake, setDisableTake] = useState(false);
    const [tableProps, setTableProps] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [config, setConfig] = useState(null);

    const tableRef = useRef();
    const modalRef = useRef();

    useEffect(() => {
        setLoading(true);

        const settings = getSettings();

        getTableProps(settings);
    }, [groupingUnitId, deploymentInfo.handle, questProjectId, orgMember]);

    const getSettings = () => {
        const currentProject = selectOptions.questProjectList.find(item => item.value === questProjectId);

        const settings = dashboardConfig[actingRole]?.questProjectList
            ?.find(item => item.label === currentProject.label)
            ?.deployments.find(item => item.handle === deploymentInfo.handle);
        return settings;
    };

    const customRenders = () => {
        return [
            {
                handle: 'lastUpdatedByName',
                render: p => {
                    const string = p.row['lastUpdatedBy.app_variable_lastUpdatedBy']?.split(', ');

                    if (string && string.length > 0) {
                        return string[0];
                    }
                    return null;
                },
            },
            {
                handle: 'lastUpdatedByEmail',
                render: p => {
                    const string = p.row['lastUpdatedBy.app_variable_lastUpdatedBy']?.split(', ');

                    if (string && string.length > 0) {
                        return string[1];
                    }
                    return null;
                },
            },
            {
                handle: 'statusTag',
                render: p => {
                    return <StatusTag props={p} />;
                },
            },
            {
                handle: 'editView',
                render: p => {
                    const status = p.row.status;
                    if (status && status !== 'Not Started') {
                        return (
                            <div>
                                <EditSurvey
                                    tableProps={props}
                                    tableRef={tableRef}
                                    deploymentId={p.row['deployment.deploymentId']}
                                    loadData={p.loadData}
                                    checkProgress={() => {
                                        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                    }}
                                    spaceRight
                                />
                                {status === 'Complete' && (
                                    <EditSurvey
                                        tableProps={props}
                                        tableRef={tableRef}
                                        deploymentId={p.row['deployment.deploymentId']}
                                        isView
                                        loadData={p.loadData}
                                        checkProgress={() => {
                                            checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                        }}
                                    />
                                )}
                            </div>
                        );
                    }
                },
            },
            {
                handle: 'editViewGoals',
                render: p => {
                    const status = p.row['devTags.GoalProgress'];

                    if (status !== 'Not Started' && status !== 'Complete') {
                        return (
                            <div>
                                <EditSurvey
                                    tableProps={props}
                                    tableRef={tableRef}
                                    deploymentId={p.row['deployment.deploymentId']}
                                    loadData={p.loadData}
                                    checkProgress={() => {
                                        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                    }}
                                />
                            </div>
                        );
                    }
                    if (status === 'Complete') {
                        return (
                            <EditSurvey
                                tableProps={props}
                                tableRef={tableRef}
                                deploymentId={p.row['deployment.deploymentId']}
                                isView
                                loadData={p.loadData}
                                checkProgress={() => {
                                    checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                }}
                                isSingle
                            />
                        );
                    }
                },
            },
            {
                handle: 'lastUpdatedByListAnnual',
                render: p => {
                    return <LastUpdatedBy {...p} isAnnual />;
                },
            },
            {
                handle: 'lastUpdatedByListOrgInfo',
                render: p => {
                    return <LastUpdatedBy {...p} isOrgInfo />;
                },
            },
            {
                handle: 'editViewNetwork',
                render: p => {
                    const status = p.row.status;
                    const orgsSelected = p.row['variables.app_variable_orgSelection'];

                    if (status && status !== 'Not Started') {
                        return (
                            <div>
                                <EditSurvey
                                    tableProps={props}
                                    tableRef={tableRef}
                                    deploymentId={p.row['deployment.deploymentId']}
                                    loadData={p.loadData}
                                    checkProgress={() => {
                                        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                    }}
                                    spaceRight
                                    orgsSelected={orgsSelected}
                                />
                                {status === 'Complete' && (
                                    <EditSurvey
                                        tableProps={props}
                                        tableRef={tableRef}
                                        deploymentId={p.row['deployment.deploymentId']}
                                        isView
                                        loadData={p.loadData}
                                        checkProgress={() => {
                                            checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                        }}
                                        orgsSelected={orgsSelected}
                                    />
                                )}
                            </div>
                        );
                    }
                },
            },
            {
                handle: 'annualActions',
                render: p => {
                    const isCompleted = p.row['devTags.CompletionStatus'] === 'Yes';
                    return (
                        <div>
                            <EditSurvey
                                tableProps={props}
                                tableRef={tableRef}
                                deploymentId={p.row['deployment.deploymentId']}
                                loadData={p.loadData}
                                checkProgress={() => {
                                    checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                }}
                                spaceRight
                            />
                            {isCompleted && (
                                <EditSurvey
                                    tableProps={props}
                                    tableRef={tableRef}
                                    deploymentId={p.row['deployment.deploymentId']}
                                    loadData={p.loadData}
                                    checkProgress={() => {
                                        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                    }}
                                    isView
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                handle: 'edit',
                render: p => {
                    const status = p.row.status;
                    if (status && status !== 'Not Started') {
                        <EditSurvey
                            tableProps={props}
                            tableRef={tableRef}
                            deploymentId={p.row['deployment.deploymentId']}
                            loadData={p.loadData}
                            checkProgress={() => {
                                checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                            }}
                            spaceRight
                        />;
                    }
                    if (status === 'Complete') {
                        return (
                            <div>
                                <EditSurvey
                                    tableProps={props}
                                    tableRef={tableRef}
                                    deploymentId={p.row['deployment.deploymentId']}
                                    loadData={p.loadData}
                                    checkProgress={() => {
                                        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                    }}
                                    isView
                                />
                            </div>
                        );
                    }
                    return null;
                },
            },
            {
                handle: 'editDeployment',
                render: p => {
                    return <EditDeployment {...p} />;
                },
            },
            {
                handle: 'profDevActions',
                render: p => {
                    const orgsSelected = p.row['orgSelection.app_variable_orgSelection'];
                    return (
                        <>
                            <EditSurvey
                                tableProps={props}
                                tableRef={tableRef}
                                deploymentId={p.row['deployment.deploymentId']}
                                loadData={p.loadData}
                                checkProgress={() => {
                                    checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                }}
                                spaceRight
                                orgsSelected={orgsSelected}
                            />
                            {p.row.status === 'Complete' && (
                                <>
                                    <EditSurvey
                                        tableProps={props}
                                        tableRef={tableRef}
                                        deploymentId={p.row['deployment.deploymentId']}
                                        loadData={p.loadData}
                                        checkProgress={() => {
                                            checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);
                                        }}
                                        isView
                                        spaceRight
                                        orgsSelected={orgsSelected}
                                    />
                                </>
                            )}
                            <ProfDevActions {...p} table={tableRef} />
                        </>
                    );
                },
            },
            {
                handle: 'selectedOrgs',
                render: p => {
                    return <SelectedOrgs props={p} />;
                },
            },
            {
                handle: 'attendees',
                render: p => {
                    return <SelectedOrgs props={p} attendees />;
                },
            },
            {
                handle: 'goalAttendeesStaff',
                render: p => {
                    return <GoalAttendance props={p} isStaff />;
                },
            },
            {
                handle: 'goalAttendeesAdminFac',
                render: p => {
                    return <GoalAttendance props={p} />;
                },
            },
            {
                handle: 'checkStatus',
                render: p => {
                    return <CheckStatus {...p} />;
                },
            },
            {
                handle: 'trainings',
                render: p => {
                    return <Trainings {...p} />;
                },
            },
            {
                handle: 'trainingsCsv',
                render: p => {
                    return <Trainings {...p} isCsv />;
                },
            },
            {
                handle: 'selectedOrgsCsv',
                render: p => {
                    return <SelectedOrgs props={p} isCsv />;
                },
            },
            {
                handle: 'attendeesCsv',
                render: p => {
                    return <SelectedOrgs props={p} attendees isCsv />;
                },
            },
            {
                handle: 'goalAttendeesStaffCsv',
                render: p => {
                    return <GoalAttendance props={p} isStaff isCsv />;
                },
            },
            {
                handle: 'goalAttendeesAdminFacCsv',
                render: p => {
                    return <GoalAttendance props={p} isCsv />;
                },
            },
            {
                handle: 'followUp',
                render: p => {
                    return <CheckTrue props={p} />;
                },
            },
            {
                handle: 'followUpCsv',
                render: p => {
                    return <CheckTrue props={p} isCsv />;
                },
            },
            {
                handle: 'nameOnly',
                render: p => {
                    return <NameOnly {...p} />;
                },
            },
            {
                handle: 'meetingType',
                render: p => {
                    return <MeetingType {...p} />;
                },
            },
        ];
    };

    const getTableProps = async settings => {
        const gqlVars = {
            assignmentId: deploymentInfo.assignmentId,
            metricsPawn: props.metricsPawn.pawnId,
            groupingUnitId,
            deploymentHandle: deploymentInfo.handle,
            deploymentId: props.deploymentId,
            orgName: deploymentInfo.orgName,
            groupRegex: `\\"orgId\\": ${groupingUnitId}`,
            projectId: props.questProjectId,
            selectedPawn: `4_${orgMember?.value}`,
            deploymentHash: deploymentInfo.hash,
            actingPawn: Fortress.user.acting.id,
        };

        const tProps = { columns: [], graphqlVariables: {} };
        // Add Column Renders
        await settings?.columns.forEach(col => {
            const newCol = { ...col };
            if (col.renderAs) {
                const render = customRenders().find(item => item.handle === col.renderAs);
                newCol.render = render?.render;
            }
            tProps.columns.push(newCol);
        });

        // add GQL Variables
        settings.gqlVariables.forEach(string => {
            tProps.graphqlVariables[string] = gqlVars[string];
        });

        const modalTitle = selectOptions.deploymentList.find(item => item.value === props.deploymentId).label;

        tProps.modalTitle = modalTitle;
        // addRowKey
        tProps.rowKey = settings.rowKey;

        tProps.orderBy = settings.orderBy;

        tProps.query = settings.query;
        setTableProps(tProps);
        setLoading(false);
    };

    const checkSurveyStatus = async (assignmentId, metricsPawn) => {
        const isSiteVisit = deploymentInfo.handle === 'orgStaffInfo';
        const response = await client.query({
            query: progressQuery,
            fetchPolicy: 'network-only',
            variables: {
                assignmentId,
                metricsPawn,
            },
        });
        const match = response?.data?.flows?.assignmentProgressList?.find(item => item.status === 'In Progress');

        if (match && isSiteVisit) {
            setDisableTake(true);
        } else {
            setDisableTake(false);
        }
    };

    const renderQuestLink = () => {
        checkSurveyStatus(deploymentInfo.assignmentId, props.metricsPawn.pawnId);

        // Old Prof Dev - Not sure if it will be used

        // if (deploymentInfo.handle === 'profDev') {
        //     return (
        //         <div className={style.rightAlign}>
        //             <AddDeployment assignmentId={deploymentInfo.assignmentId} orgId={groupingUnitId} table={tableRef} />
        //         </div>
        //     );
        // }

        return (
            <div className={style.flex}>
                <Modal
                    ref={modalRef}
                    trigger={
                        <button type="button" className="btn btn-primary" disabled={disableTake}>
                            Take Survey
                        </button>
                    }
                    title={tableProps && tableProps.modalTitle}
                    size="large"
                    afterClose={() => {
                        tableRef.current.loadData();
                        dispatch(setOrgListChanged(false));
                        dispatch(setOrgList([]));
                    }}
                >
                    <div className={style.fixContainer}>
                        <Survey {...props} tableRef={tableRef} modalRef={modalRef} isNew />
                    </div>
                </Modal>
                <div
                    className={style.status}
                    style={
                        deploymentInfo.isOpen
                            ? {
                                  color: '#5CB85C',
                              }
                            : {
                                  color: '#F85152',
                              }
                    }
                >
                    {deploymentInfo.isOpen ? 'OPEN' : 'CLOSED'}
                </div>
            </div>
        );
    };

    if (loading) {
        return <Loader loading />;
    }
    return (
        <>
            {tableProps && (
                <>
                    {renderQuestLink()}{' '}
                    <Table
                        csvFilename={`${deploymentInfo.assignmentLabel} - ${props.getOrgName(groupingUnitId)}`}
                        key={deploymentInfo.handle}
                        ref={tableRef}
                        {...tableProps}
                    />
                </>
            )}
        </>
    );
};

DashboardTable.propTypes = {
    orgList: PropTypes.array,
    actingId: PropTypes.number,
    actingRole: PropTypes.string,
    deploymentId: PropTypes.number,
    deploymentInfo: PropTypes.object,
    getOrgName: PropTypes.func,
    groupingUnitId: PropTypes.number,
    metricsPawn: PropTypes.object,
    orgMember: PropTypes.object,
    questProjectId: PropTypes.number,
    selectOptions: PropTypes.object,
};

const mapStateToProps = state => ({
    orgList: state.app.orgList.orgList,
});

export default connect(mapStateToProps, {})(DashboardTable);
