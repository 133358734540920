import React from 'react';
import _uniq from 'lodash/uniq';
import Modal from 'cccisd-modal';
import _last from 'lodash/last';

const GoalAttendance = ({ props, isStaff, isCsv }) => {
    const deploymentId = props.row['deployment.deploymentId'];

    const assignmentProgressList = props.extraData['matrixData.assignmentProgressList']?.filter(
        item => item.deployment.deploymentId === deploymentId
    );

    const matrixResponses = assignmentProgressList?.filter(item => item.status !== 'Complete');

    const staffAttended = matrixResponses?.filter(item => item.devTags.StaffGoalsAttended);

    const adminFacAttended = matrixResponses?.filter(item => item.devTags.AdminFacAttended);

    const collection = isStaff
        ? staffAttended?.map(item => item.pawn.user.fullName)
        : adminFacAttended?.map(item => item.pawn.user.fullName);

    const list = _uniq(collection);

    if (isCsv) {
        const last = _last(list);
        return (
            <>
                {list &&
                    list.map((item, i) => {
                        if (item === last) {
                            return `${item}`;
                        }
                        return `${item}, `;
                    })}
            </>
        );
    }
    return (
        <>
            {list && (
                <Modal
                    key={deploymentId}
                    trigger={<a href="#">{list.length}</a>}
                    title={isStaff ? 'PCANC Staff Members' : 'Organization Admins / Facilitators'}
                    size="medium"
                    beforeShow={() => {}}
                    afterClose={() => {}}
                    disabled={list.length === 0}
                >
                    <ul>
                        {list.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                </Modal>
            )}
        </>
    );
};

export default GoalAttendance;
