import Modal from 'cccisd-modal';
import React, { useRef } from 'react';
import { Formik, Form, Field, CccisdDatepicker } from 'cccisd-formik';
import axios from 'cccisd-axios';
import PencilIcon from 'cccisd-icons/pencil2';
import moment from 'moment';
import style from './style.css';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;
// NOT PROF DEV EDIT BUTTON!

const AddDeployment = props => {
    const modal = useRef();

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };
    // public function update(Request $request, $deploymentId)
    // {
    //     $response = new NexusResponse();

    //     $deployment = Deployment::find($deploymentId);

    //     $roleHandle = $request->input('roleHandle');
    //     $role       = Role::where('handle', '=', $roleHandle)->first();

    //     $type        = $request->input('type');
    //     $label       = $request->input('label');
    //     $description = $request->input('description');
    //     $handle      = $request->input('handle');
    //     $timepoint   = $request->input('timepoint');
    //     $isOpen      = $request->input('isOpen');
    //     $opensAt     = $request->input('opensAt');
    //     $closesAt    = $request->input('closesAt');
    //     $endpoint    = $request->input('endpoint');
    //     $settings    = $request->input('settings', []);

    const onSubmit = async values => {
        try {
            const params = {
                assignmentId: props.row['assignment.assignmentId'],
                description: null,
                roleHandle: 'metricspawn',
                type: 'group',
                hash: props.row.hash,
                isOpen: false,
                label: props.row.label,
                respondentsFromDcwId: null,
                opensAt: applyTimezoneOffset(values.openDate),
                closesAt: applyTimezoneOffset(values.closeDate),
                timepoint: '',
                settings: {
                    options: props.row['settings.options'],
                    orgId: props.row['settings.orgId'],
                    orgName: props.row['settings.orgName'],
                    recaptcha: false,
                    autoLogout: true,
                    isRepeated: false,
                    allowRetake: false,
                    requirePass: false,
                    useEndpoint: false,
                    closedRoster: false,
                    requireLogin: true,
                    closeOnTarget: false,
                    closedMessage: 'This survey is no longer accepting responses.',
                    completionUrl: null,
                    passcodeLabel: 'Your Passcode',
                    autoLogoutTime: 20,
                    repeatInterval: null,
                    completedMessage: 'You have already completed this survey. Thank you!',
                    respondentConfig: 'managed',
                    completionMessage: 'You have completed the survey! Thank you for your response!',
                    otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                    chainEmailFromPawn: null,
                    chainPhoneFromPawn: null,
                    individualPasscode: false,
                    logoutOnCompletion: false,
                    passcodeBackground: 'solidColor',
                    respondentSelection: 'metricspawn',
                    postCompletionAction: 'message',
                    anonAutoLogoutMessage:
                        '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
                    knownAutoLogoutMessage:
                        '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
                    passcodeBackgroundColor: '#F1F0F5',
                    branchedCompletionConfig: [],
                    isAssignmentPlanEligible: false,
                    passcodeCompletionButtonText: 'Ok',
                    showReviewButtonOnCompletion: false,
                    metricToRespondentPropertyMappings: [],
                },
            };

            const response = await axios.put(
                Boilerplate.route('api.assignmentDeployment.update', {
                    deploymentId: props.row.deploymentId,
                }),
                params
            );

            if (response?.data?.status === 'success') {
                closeModal();
                props.loadData();
            } else {
                closeModal();
                notification({ message: response?.data?.errors?.group[0], type: 'danger' });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const closeModal = () => {
        modal.current.close();
    };

    const validate = values => {
        try {
            let errors = {};
            if (!values.openDate) {
                errors.openDate = 'Open Date is required';
            }

            if (!values.closeDate) {
                errors.closeDate = 'Close Date is required';
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-sm btn-primary">
                    <PencilIcon />
                </button>
            }
            title="Add Deployment"
            size="medium"
        >
            <Formik
                onSubmit={onSubmit}
                validate={validate}
                render={() => (
                    <Form>
                        <p>Session ID: {props.row.deploymentId}</p>
                        <div>TA Org: {props.row.deploymentId}</div>
                        <div className={style.dates}>
                            <div>
                                <label className={`control-label ${style.required}`}>Open Date:</label>
                                <Field name="openDate" component={CccisdDatepicker} showTimeSelect />
                            </div>
                            <div>
                                <label className={`control-label ${style.required}`}>Close Date:</label>
                                <Field name="closeDate" component={CccisdDatepicker} showTimeSelect />
                            </div>
                        </div>
                        <div className={style.dates}>
                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                Back
                            </button>
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default AddDeployment;
