import React, { useState, useEffect } from 'react';
import Modal from 'cccisd-modal';
import Clipboard from 'cccisd-icons/clipboard4';
const LastUpdatedBy = props => {
    const [values, setValues] = useState(null);

    const annualLabels = {
        'lastUpdatedBy.app_variable_lastUpdatedBy': 'Engagment Plan',

        'lastUpdatedByPrg.prgm_lastupdatedby': 'Programatic Information',

        'lastUpdatedByQ1.progress_Q1_lastupdatedby': 'Quarter 1 Progress',

        'lastUpdatedByQ2.progress_Q2_lastupdatedby': 'Quarter 2 Progress',

        'lastUpdatedByQ3.progress_Q3_lastupdatedby': 'Quarter 3 Progress',

        'lastUpdatedByQ4.progress_Q4_lastupdatedby': 'Quarter 4 Progress',
    };

    const orgStaffLabels = {
        'lastUpdatedBy2.app_variable_lastUpdatedBy': 'Organization Information',
        'otherUpdatedBy.221_app_variable_lastUpdatedBy': 'Staff Information',
    };
    useEffect(() => {
        if (props.isAnnual) {
            const arr = [];
            for (const [key, value] of Object.entries(annualLabels)) {
                const match = props.row[key];
                if (match) {
                    arr.push({ value, match });
                }
            }
            setValues(arr);
        }
        if (props.isOrgInfo) {
            const arr = [];
            for (const [key, value] of Object.entries(orgStaffLabels)) {
                const match = props.row[key];

                if (match) {
                    arr.push({ value, match });
                }
            }
            setValues(arr);
        }
    }, []);

    return (
        <>
            {values && values.length > 0 && (
                <Modal
                    trigger={
                        <button type="button" className="btn btn-primary">
                            <Clipboard />
                        </button>
                    }
                    title="People last saved"
                    size="medium"
                >
                    <div>
                        <table className="table table-striped tabled-bordered">
                            <thead>
                                <tr>
                                    <th>Survey</th>
                                    <th>Person</th>
                                </tr>
                            </thead>
                            <tbody>
                                {values.map(item => {
                                    return (
                                        <tr>
                                            <td>{item.value}</td>
                                            <td>{item.match}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default LastUpdatedBy;
