import Modal from 'cccisd-modal';
import React, { useState, useRef, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import { Formik, Form, Field, CccisdInput, CccisdDatepicker, CccisdToggle } from 'cccisd-formik';
import axios from 'cccisd-axios';
import PlusIcon from 'cccisd-icons/plus2';
import PencilIcon from 'cccisd-icons/pencil5';
import CopyIcon from 'cccisd-icons/copy';
import moment from 'moment';
import style from './style.css';
import notification from 'cccisd-notification';
import surveyListQuery from '../../../graphql/renders/surveyList.graphql';
import _last from 'lodash/last';
import Tooltip from 'cccisd-tooltip';

const Boilerplate = window.cccisd.boilerplate;

const ProfDevActions = props => {
    const modal = useRef();
    const [surveyList, setSurveyList] = useState(null);
    const [assignmentId, setAssignmentId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentDeployment, setCurrentDeployment] = useState(null);

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const assignmentProgressRegex = `\\"assignmentProgressId\\": ${props.row.assignmentProgressId}`;

        const response = await client.query({
            query: surveyListQuery,
            fetchPolicy: 'network-only',
            // CHANGE TO ACTUAL WHEN WE KNOW (PROF DEV DOC button assignment)
            variables: { assignmentHandle: 'assignment50', assignmentProgressRegex },
        });

        const list = response?.data?.flows?.assignment?.surveyList.map(item => {
            return { handle: item.surveyHandle, label: item.label };
        });
        const dep = response?.data?.flows?.deployment;
        const open = dep?.isOpen;

        setIsOpen(open);
        setCurrentDeployment(dep);
        setAssignmentId(response?.data?.flows?.assignment?.assignmentId);
        setSurveyList(list);
    };

    const onSubmit = async values => {
        const allSurveys = surveyList?.map(item => item.handle);

        const options = [];

        allSurveys.forEach(item => {
            if (values[item]) {
                options.push(item);
            }
        });

        if (!currentDeployment.hash) {
            // create
            try {
                const params = {
                    assignmentId,
                    description: null,
                    hash: Math.random().toString(36).substring(2, 9),
                    label: values.deploymentName,
                    roleHandle: 'respondent',
                    opensAt: applyTimezoneOffset(values.openDate),
                    closesAt: applyTimezoneOffset(values.closeDate),
                    settings: {
                        // add surveyList
                        options,
                        assignmentProgressId: props.row.assignmentProgressId,
                        recaptcha: false,
                        autoLogout: true,
                        isRepeated: false,
                        allowRetake: false,
                        requirePass: false,
                        useEndpoint: false,
                        closedRoster: false,
                        requireLogin: true,
                        closeOnTarget: false,
                        closedMessage: 'This survey is no longer accepting responses.',
                        completionUrl: null,
                        passcodeLabel: 'Your Passcode',
                        autoLogoutTime: 20,
                        repeatInterval: null,
                        completedMessage: 'You have already completed this survey. Thank you!',
                        respondentConfig: 'managed',
                        completionMessage: 'You have completed the survey! Thank you for your response!',
                        otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                        chainEmailFromPawn: null,
                        chainPhoneFromPawn: null,
                        individualPasscode: false,
                        logoutOnCompletion: false,
                        passcodeBackground: 'solidColor',
                        respondentSelection: 'metricspawn',
                        postCompletionAction: 'message',
                        anonAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
                        knownAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
                        passcodeBackgroundColor: '#F1F0F5',
                        branchedCompletionConfig: [],
                        isAssignmentPlanEligible: false,
                        passcodeCompletionButtonText: 'Ok',
                        showReviewButtonOnCompletion: false,
                        metricToRespondentPropertyMappings: [],
                    },
                    timepoint: '',
                    type: 'group',
                };

                const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), params);

                if (response?.data?.status === 'success') {
                    closeModal();
                    props.table.current.loadData();
                    notification({ message: 'Deployment successfully created', type: 'success' });
                } else {
                    closeModal();

                    if (response?.data?.errors?.closesOn) {
                        notification({
                            message: 'Close Date must be after Open Date',
                            type: 'danger',
                        });
                    }
                    if (response?.data?.errors?.opensAt) {
                        notification({
                            message: 'Open Date must be in the future',
                            type: 'danger',
                        });
                    }
                    if (response?.data?.errors?.group) {
                        notification({
                            message: 'You do not have permission to perform this action',
                            type: 'danger',
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            try {
                // edit
                const params = {
                    ...currentDeployment,
                    label: values.deploymentName,
                    opensAt: applyTimezoneOffset(values.openDate),
                    closesAt: applyTimezoneOffset(values.closeDate),
                    settings: {
                        ...currentDeployment.settings,
                        options,
                        assignmentProgressId: props.row.assignmentProgressId,
                    },
                };

                const response = await axios.put(
                    Boilerplate.route('api.assignmentDeployment.update', {
                        deploymentId: currentDeployment.deploymentId,
                    }),
                    params
                );

                if (response?.data?.status === 'success') {
                    closeModal();
                    props.table.current.loadData();
                    notification({ message: 'Deployment successfully edited', type: 'success' });
                } else {
                    closeModal();
                    if (response?.data?.errors?.closesOn) {
                        notification({
                            message: 'Close Date must be after Open Date',
                            type: 'danger',
                        });
                    }
                    if (response?.data?.errors?.opensAt) {
                        notification({
                            message: 'Open Date must be in the future',
                            type: 'danger',
                        });
                    }
                    notification({ message: response?.data?.errors?.group[0], type: 'danger' });
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    const closeModal = () => {
        modal.current.close();
    };

    const validate = values => {
        try {
            let errors = {};
            if (!values.deploymentName) {
                errors.deploymentName = 'Deployment Name is required';
            }

            if (!values.openDate) {
                errors.openDate = 'Open Date is required';
            }

            if (!values.closeDate) {
                errors.closeDate = 'Close Date is required';
            }

            if (surveyList && surveyList.length > 0) {
                const selectedSurveys = [];

                surveyList.forEach(survey => {
                    if (values[survey.handle]) {
                        selectedSurveys.push(survey.handle);
                    }
                });

                if (selectedSurveys.length === 0) {
                    const last = _last(surveyList).handle;
                    errors[last] = 'You must select at least one survey';
                }
            }
            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    const getInitialValues = () => {
        if (!currentDeployment.hash) {
            return {};
        }
        const initialValues = {};

        currentDeployment.settings.options.forEach(item => {
            initialValues[item] = true;
        });

        initialValues.deploymentName = currentDeployment.label;
        initialValues.openDate = currentDeployment.opensAt;
        initialValues.closeDate = currentDeployment.closesAt;

        return initialValues;
    };

    return (
        <>
            {surveyList && assignmentId && (
                <Modal
                    ref={modal}
                    trigger={
                        <Tooltip title={!currentDeployment?.hash ? 'Create Deployment' : 'Edit Deployment'}>
                            <button
                                type="button"
                                className={!currentDeployment?.hash ? 'btn btn-default' : 'btn btn-warning'}
                                style={{ marginRight: '1em' }}
                            >
                                {!currentDeployment?.hash ? (
                                    <>
                                        <PlusIcon />
                                    </>
                                ) : (
                                    <>
                                        <PencilIcon />
                                    </>
                                )}
                            </button>
                        </Tooltip>
                    }
                    title="Add Deployment"
                    size="medium"
                    afterClose={() => {
                        getData();
                    }}
                >
                    <Formik
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={!currentDeployment.hash ? {} : getInitialValues()}
                        render={() => (
                            <Form>
                                <div className={style.formSection}>
                                    <label className={`control-label ${style.required}`}>Deployment Name:</label>
                                    <Field name="deploymentName" component={CccisdInput} />
                                </div>
                                <div className={style.dates}>
                                    <div>
                                        <label className={`control-label ${style.required}`}>Open Date:</label>
                                        <Field
                                            name="openDate"
                                            component={CccisdDatepicker}
                                            showTimeSelect
                                            disabled={isOpen}
                                        />
                                    </div>
                                    <div>
                                        <label className={`control-label ${style.required}`}>Close Date:</label>
                                        <Field name="closeDate" component={CccisdDatepicker} showTimeSelect />
                                    </div>
                                </div>
                                <div className={style.formSection}>
                                    <label className={`control-label ${style.required}`}>Survey Selection:</label>
                                    {surveyList &&
                                        surveyList.length > 0 &&
                                        surveyList.map(item => {
                                            return (
                                                <Field name={item.handle} component={CccisdToggle} label={item.label} />
                                            );
                                        })}
                                </div>
                                <div className={style.dates}>
                                    <button
                                        className="btn btn-default"
                                        type="button"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        Back
                                    </button>
                                    <button className="btn btn-primary" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    />
                </Modal>
            )}
            {currentDeployment?.hash && (
                <Tooltip title={'Copy Url: ' + Boilerplate.url(`/d/${currentDeployment.hash}`)}>
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                            navigator.clipboard.writeText(Boilerplate.url(`/d/${currentDeployment.hash}`));
                            notification({ message: 'Copied to clipboard', type: 'success' });
                        }}
                    >
                        <CopyIcon />
                    </button>
                </Tooltip>
            )}
        </>
    );
};

export default ProfDevActions;
